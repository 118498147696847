import { render, staticRenderFns } from "./ClientTrigger.vue?vue&type=template&id=355c2f83&scoped=true&lang=pug&"
import script from "./ClientTrigger.vue?vue&type=script&lang=ts&"
export * from "./ClientTrigger.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "355c2f83",
  null
  
)

export default component.exports