"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const rose_dayjs_1 = require("../rose-dayjs");
const dateRanges_1 = require("../dateRanges");
exports.default = (0, vue_1.defineComponent)({
    filters: {
        date(val) {
            return val ? (0, rose_dayjs_1.roseDayjs)(val).format('DD.MM.YYYY') : '';
        },
    },
    props: {
        disabled: { type: Boolean },
        readonly: { type: Boolean },
        dateEnabled: { type: Boolean },
        dateRange: { type: Object, default: () => ({ startDate: null, endDate: null }) },
        singleDatePickerProp: { type: Boolean },
        hideRanges: { type: Boolean },
        autoOpen: { type: Boolean },
        hideCalendarIcon: { type: Boolean },
        opens: { default: 'smart', type: String },
    },
    data: () => ({
        initialized: false,
        // Date
        isOpened: false,
        ranges: null,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showDropdowns: true,
        autoApply: true,
        showWeekNumbers: true,
        linkedCalendars: false,
        alwaysShowCalendars: true,
        appendToBody: true,
        closeOnEsc: true,
        locale: {
            direction: 'ltr',
            format: 'dd.mm.yyyy',
            separator: ' - ',
            applyLabel: 'Anwenden',
            cancelLabel: 'Abbrechen',
            weekLabel: 'W',
            customRangeLabel: 'Benutzerdefinierten Bereich',
            daysOfWeek: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
            monthNames: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
            ],
            firstDay: 1,
        },
        // smart open positions
        elementRect: null,
    }),
    computed: {
        opensSmart() {
            if (this.opens === 'smart') {
                if (!this.elementRect) {
                    return 'center';
                }
                let rectCenter = this.elementRect.left + this.elementRect.width / 2;
                let windowWidth = window.innerWidth;
                if (rectCenter < windowWidth / 3) {
                    return 'right';
                }
                else if (rectCenter > (windowWidth / 3) * 2) {
                    return 'left';
                }
                else {
                    return 'center';
                }
            }
            return this.opens;
        },
    },
    mounted() {
        this.init();
        if (this.autoOpen) {
            setTimeout(() => {
                this.openPicker();
            });
        }
    },
    methods: {
        init() {
            var _a;
            try {
                this.initialized = true;
                this.singleDatePicker = (_a = this.singleDatePickerProp) !== null && _a !== void 0 ? _a : false;
                this.ranges = this.hideRanges ? null : dateRanges_1.allRangesKeyValue;
            }
            catch (e) {
                this.initialized = true;
                console.error(e);
            }
        },
        openPicker(event) {
            this.isOpened = !this.isOpened;
            // @ts-ignore
            this.$refs.picker.togglePicker(this.isOpened);
            event === null || event === void 0 ? void 0 : event.stopPropagation();
        },
        dateUpdate(v) {
            console.log('event: update', Object.assign({}, v));
            // eslint-disable-next-line vue/no-mutating-props
            this.dateRange.startDate = (0, rose_dayjs_1.roseDayjs)(v.startDate);
            // eslint-disable-next-line vue/no-mutating-props
            this.dateRange.endDate = (0, rose_dayjs_1.roseDayjs)(v.endDate);
            this.$emit('date-update', {
                startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
                endDate: this.dateRange.endDate.format('YYYY-MM-DD'),
            });
        },
        dateOpen(open) {
            this.isOpened = open;
            console.log('event: open', open);
            if (this.opens === 'smart') {
                let hostElm = this.$refs.hostElm;
                if (hostElm) {
                    this.elementRect = hostElm.getBoundingClientRect();
                }
                else {
                    this.elementRect = null;
                }
            }
            // predefined ranges to the right
            setTimeout(() => {
                let calendar = document.querySelector('.daterangepicker.show-ranges');
                if (calendar) {
                    let container = calendar.querySelector('.calendars');
                    let ranges = container.querySelector('.ranges');
                    container.removeChild(ranges);
                    container.appendChild(ranges);
                }
            });
        },
        dateFormat(classes, date) {
            let yesterday = new Date();
            let d1 = (0, rose_dayjs_1.roseDayjs)(date).format('isoDate');
            let d2 = (0, rose_dayjs_1.roseDayjs)(yesterday.setDate(yesterday.getDate() - 1), 'isoDate').toString();
            if (!classes.disabled) {
                classes.disabled = d1 === d2;
            }
            return classes;
        },
    },
});
