import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=3477a022&scoped=true&lang=pug&"
import script from "./Clients.vue?vue&type=script&lang=ts&"
export * from "./Clients.vue?vue&type=script&lang=ts&"
import style0 from "./Clients.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Clients.vue?vue&type=style&index=1&id=3477a022&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3477a022",
  null
  
)

export default component.exports