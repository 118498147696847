"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const data_1 = require("@/services/data");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'ClientTrigger',
    props: {
        isSolutioTeam: Boolean,
        selectedClient: { type: Object, default: null },
    },
    data: () => ({
        heuteDaysBack: 5,
    }),
    methods: {
        triggerIncrementalCollect() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerIncrementalCollect)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Update Sammlung gestartet');
        },
        triggerFullCollect() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerFullCollect)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Komplett Sammlung gestartet');
        },
        triggerRebuildDb() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerRebuildDb)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Rebuild DB Trigger');
        },
        triggerTerminbuch() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerTerminbuch)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Terminbuch Trigger');
        },
        triggerUmsatz() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerUmsatz)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Umsatz Trigger');
        },
        triggerLeistungsbloecke() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerLeistungsbloecke)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Leistungsbloecke Trigger');
        },
        triggerBenchmark() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerBenchmark)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Benchmark Trigger');
        },
        triggerLeistungsautomatik() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerLeistungsautomatik)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid); }, 'Leistungs-Automatik Trigger');
        },
        triggerTodomailer() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => (0, data_1.triggerTodomailer)(true), 'Todomailer Trigger');
        },
        triggerMetaUpdate() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerMetaUpdate)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid); }, 'Update Meta Trigger');
        },
        triggerHeute() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerHeute)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, this.heuteDaysBack, true); }, 'Heute Trigger');
        },
        triggerHkp() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerHkp)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'triggerHkp');
        },
        triggerAbrechnungsautomat() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerAbrechnungsautomat)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'Abrechnungsautomat Trigger');
        },
        triggerPA() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => { var _a; return (0, data_1.triggerPA)((_a = this.selectedClient) === null || _a === void 0 ? void 0 : _a.cid, true); }, 'PA Trigger');
        },
        triggerHkpmailer() {
            this.$emit('handleApiCallWithResponseStatusInfo', () => (0, data_1.triggerHkpmailer)(true), 'triggerHkpmailer');
        },
    },
});
